@use 'colors';
@use 'typography';

@font-face {
  font-family: "CA SaygonText";
  src: url(/assets/fonts/CASaygonText/CASaygonText-Regular.woff)
    format("opentype");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: CaSaygon, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --primary: #00e169;
  --background: #fcfbfa;
  --secondary: #ebe9e8;
  --text-primary: #000000;
  --text-secondary: #7f7f85;
  --text-tertiary: #54545c;
  --white-color: #ffffff;
  --inactive: hsla(0, 0, 0, 0.33);
  --border-color: #a6a6ab;
  --footer-background: #ebe9e8;
  --background-secondary: #ffffff;
  --background-button: #ffffff;
  --backgroud-header-home: #46b655;
  --background-modal: #dad0d0;
  --text-other: #35353b;
  --background-hover: #00c16d;
  --background-light: #33b163;
  --background-light-pink: #FC8181;
  --modal-button-text-color: #262b2b;
  --modal-description-text-color: #4c5555;
  --background-light: #33b163;
  --background-button2: #00E169;
  --background-button2-hover: #00C16D;
  --background-button3: #d5dddd;
  --background-button3-hover: #c1cccc;
  --background-button-ghost: #acbbbb;
  --module-title: #00772e;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main:not(.brius_quiz_maker-main) {
  flex: 1;
  background: var(--background);
}

button {
  font-family: CaSaygon, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.brius_quiz_maker {
  min-height: 100% !important;

  .brius_quiz_maker-brand-image {
    width: 100% !important;
  }
}

@font-face {
  font-family: "CaSaygon";
  src: url("../public/assets/fonts/CASaygonText/CASaygonText-Thin.woff2")
      format("woff2"),
    url("../public/assets/fonts/CASaygonText/CASaygonText-Thin.woff")
      format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CaSaygon";
  src: url("../public/assets/fonts/CASaygonText/CASaygonText-ThinItalic.woff2")
      format("woff2"),
    url("../public/assets/fonts/CASaygonText/CASaygonText-ThinItalic.woff")
      format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CaSaygon";
  src: url("../public/assets/fonts/CASaygonText/CASaygonText-Light.woff2")
      format("woff2"),
    url("../public/assets/fonts/CASaygonText/CASaygonText-Light.woff")
      format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CaSaygon";
  src: url("../public/assets/fonts/CASaygonText/CASaygonText-LightItalic.woff2")
      format("woff2"),
    url("../public/assets/fonts/CASaygonText/CASaygonText-LightItalic.woff")
      format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CaSaygon";
  src: url("../public/assets/fonts/CASaygonText/CASaygonText-Regular.woff2")
      format("woff2"),
    url("../public/assets/fonts/CASaygonText/CASaygonText-Regular.woff")
      format("woff"),
    url("../public/assets/fonts/CASaygonText/CASaygonText-Regular.ttf")
      format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CaSaygon";
  src: url("../public/assets/fonts/CASaygonText/CASaygonText-Medium.woff2")
      format("woff2"),
    url("../public/assets/fonts/CASaygonText/CASaygonText-Medium.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CaSaygon";
  src: url("../public/assets/fonts/CASaygonText/CASaygonText-MediumItalic.woff2")
      format("woff2"),
    url("../public/assets/fonts/CASaygonText/CASaygonText-MediumItalic.woff")
      format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "CaSaygon";
  src: url("../public/assets/fonts/CASaygonText/CASaygonText-Semibold.woff2")
      format("woff2"),
    url("../public/assets/fonts/CASaygonText/CASaygonText-Semibold.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CaSaygon";
  src: url("../public/assets/fonts/CASaygonText/CASaygonText-SemiboldItalic.woff2")
      format("woff2"),
    url("../public/assets/fonts/CASaygonText/CASaygonText-SemiboldItalic.woff")
      format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "CaSaygon";
  src: url("../public/assets/fonts/CASaygonText/CASaygonText-Bold.woff2")
      format("woff2"),
    url("../public/assets/fonts/CASaygonText/CASaygonText-Bold.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CaSaygon";
  src: url("../public/assets/fonts/CASaygonText/CASaygonText-BoldItalic.woff2")
      format("woff2"),
    url("../public/assets/fonts/CASaygonText/CASaygonText-BoldItalic.woff")
      format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "CaSaygon";
  src: url("../public/assets/fonts/CASaygonText/CASaygonText-Extrabold.woff2")
      format("woff2"),
    url("../public/assets/fonts/CASaygonText/CASaygonText-Extrabold.woff")
      format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CaSaygon";
  src: url("../public/assets/fonts/CASaygonText/CASaygonText-ExtraboldItalic.woff2")
      format("woff2"),
    url("../public/assets/fonts/CASaygonText/CASaygonText-ExtraboldItalic.woff")
      format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
