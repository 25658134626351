.header {
  position: relative;
  padding: 2rem 1rem 0 1rem;
  overflow: hidden;
  background-color: var(--background);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 640px) {
    padding-top: 0.5rem;
  }
  
  @media (min-width: 1025px) {
    padding: 2rem 0 0 0;
  }
}

.header a {
  color: var(--text-primary);
  text-align: center;
  text-decoration: none;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.headerRight {
  margin-right: 3rem;
}

.headerAvatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 8px 7px;
  cursor: pointer;
  background-color: var(--background-light-pink);
  text-align: center;
  color: white;
  font-size: 12px;
  font-weight: bold;
  margin-right: 3rem;
  margin-left: 1rem;
}

.navigation {
  display: flex;
}

.headerRight a {
  padding-top: 0.7rem;
  margin-right: 3rem;
}

.headerRightMobile {
  display: flex;
}

.headerRightMobile {
  &>* { margin-right: 32px; }
  &>*:last-child { margin-right: 0; }
}

.headerRightMobile a {
  padding-top: 0;
}

.logo {
  margin-left: 2rem;

  @media (max-width: 1025px) {
    margin-left: 0;
  }
}

@media screen and (max-width: 700px) {
  .header a {
    text-align: left;
  }

  .headerRight {
    display: none;
  }

  .headerRightMobile a {
    padding-top: 0;
  }
}
