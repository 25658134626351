@use 'styles/_colors.scss';

$md-1: 8px;
$md-half: $md-1/2;

.textInputContainer {
  display: flex;
  flex-direction: column;
}

// verificar se haveria conflito caso redeclarado em outro scss
.label {
  margin-bottom: $md-half;
}

.fieldWrapper {
  display: flex;
  align-items: center;
  border: 1px solid #A6A6AB;
  border-radius: $md-half;

  &:focus {
    color: red;
  }
}

.input {
  border: none;
  width: 100%;
  background: none;
  box-sizing: border-box;
  padding: $md-1;

  &:disabled {
    background: colors.$gray;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    opacity: 0.5;
  }
}

.errorField {
  border: 2px solid colors.$red;
}

.errorIcon {
  padding-right: $md-1;
}

.errorMessage {
  color: colors.$red;
  font-weight: 400;
}
