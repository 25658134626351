.modalContainer {
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 312px;
    min-height: 448px;
    text-align: center;
    padding: 24px;
    border-radius: 8px;

    .button {
        margin-top: 2rem;
    }

    > .content{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        margin-top: 2rem;
    }

    > .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
    }
}