@use 'styles/_colors.scss';

$md1: 8px;
$md2: 2*$md1;
$md3: 3*$md1;
$md4: 4*$md1;
$md6: 6*$md1;

$md-half: $md1/2;

.default {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: $md6;

  padding: $md1 0px;

  border-radius: $md-half;

  font-size: 0.75rem;
  font-weight: 550;
  line-height: 1.125rem;
  word-spacing: 7%;
  text-transform: uppercase;

  border: none;

  cursor: pointer;
}

.white {
  background-color: colors.$white;
  color: #262B2B;
  border: 1px solid #ACBBBB;
}

.white-border-blue {
  background-color: colors.$white;
  color: #262B2B;
  border: 1px solid #5CCFE5;
}

.primary {
  background-color: colors.$green;
  color: #fff;

  &:disabled {
    background-color: colors.$button-primary-inactive;
    opacity: 0.33;
  }
}

.primary-dark {
  background-color: colors.$dark-green;
  color: #fff;
}

