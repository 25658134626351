  @use './_colors.scss';

  .container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .card {
    display: flex;
    flex-direction: column;
    z-index: 1;
    background-color: var(--background-secondary);
    min-width: 21.875rem;
    margin-top: 1rem;
    padding: 1.5rem 2rem 2rem 2rem;
    text-align: center;
    color: inherit;
    text-decoration: none;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(49, 5, 55, 0.1);
  }

  .title {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--text-brand-neutral);
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .form_field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    text-align: left;
  }

  .separator {
    margin: 1rem 0;
    display: flex;
    align-items: center;
  }

  .line {
    border-top: 1px solid var(--border-color);
    flex-grow: 1;
    margin: 0 10px;
    vertical-align: middle;
  }
  
  .btn {
    z-index: 10;
    position: relative;
    padding: 1rem 2.3rem;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 550;
  }
  
  .btn:hover {
    position: relative;
    cursor: pointer;
  }

  .customBtn {
    @extend .btn;
    margin-bottom: 1.5rem;
    border: solid 1px var(--text-secondary);

    &:hover {
      background-color: var(--white-color);
    }
  }

  .actionBtn {
    @extend .btn;
    margin-top: 0.5rem;
    background-color: var(--background-button2);
    color: var(--white-color);

    &:hover {
      background-color: var(--background-button2-hover);
    }
  }

  .icon {
    position: relative;
    top: 0.12rem;
    margin-right: 0.5rem;
  }

  .text {
    position: relative;
    font-size: 14px;
    font-weight: 400;
  }

  .highlight_text {
    text-transform: uppercase;
    font-weight: 600;
  }

  .underline_text {
    text-decoration: underline;
    margin-top: 1.5rem;
  }

  .ellipsis {
    position: fixed;
    bottom: -48.5%;
    z-index: -1;
    width: 110%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--primary);
  }


  @media (max-width: 600px) {
      .ellipsis {
        position: fixed;
        bottom: -32%;
        z-index: -1;
        width: 619px;
        height: 619px;
        border-radius: 50%;
        background-color: var(--primary);
      }

      .card {
        min-width: 90%;
      }
    }
 