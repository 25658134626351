.courseCardContainer {
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(49, 5, 55, 0.1);
}

.imgCourseCard {
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0px 0px;
}

.divInfoCourse {
  width: 100%;
  box-sizing: border-box;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.titleCourseCard {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.875rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.descriptionCourseCard {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  margin-bottom: 1rem;
}

.divStatusCourseCard {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}

.iconStatus {
  margin-right: 8px;
}

.labelStatus {
  font-size: 1rem;
  font-weight: bold;
  color: var(--primary);
}

@media (min-width: 720px) {
  .courseCardContainer {
    max-width: 100%;
  }
}
