
/* AUTOMATICALLY GENERATED FILE; EDIT WITH CARE */

$primary: #00E169;
$background: #FCFBFA;
$footer: #EBE9E8;
$footer-icons: #35353B;
$header: #ed1d1d;
$dark-green: #00C16D;

$primary: #00E169;
$secondary: #ebe9e8;
$text-primary: #000000;
$text-secondary: #7f7f85;
$text-brand-neutral: #262B2B;
$inactive: hsla(0, 0%, 0%, 0.33);
$border-color: #a6a6ab;
$footer-background: #4a4b4c;

$link-light-blue: #4299E1;

$button-primary-inactive: #00E169;

$black: #000000;
$white: #ffffff;
$neutral: #7F7F85;
$green: #00E169;
$red: #d21c1c;
$gray: #ebe9e8;

$background: #FCFBFA;
