.footer {
  background-color: var(--footer-background);
  width: 100%;
}

.container {
  width: 100% !important;
  height: 84px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  fill: var(--secondary);
  color: var(--secondary);
  padding: 24px;
}

.container section {
  height: 100%;
  min-height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.socialMediaArea {
  min-width: fit-content;
}

.socialMediaArea a {
  width: 28px;
  height: 28px;
}

.socialMediaArea a + a {
  margin-left: 0.75rem;
}

@media (min-width: 720px) {
  .container {
    padding: 32px 72px;
  }
}
