h1 {
  size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
}

h3 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.875rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
}
