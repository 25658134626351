/* Posição e tamanho do Menu sanduíche */
.bm-burger-button {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
}

.bm-burger-button>img {
  position: absolute;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--text-primary);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: var(--text-primary);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  position: inherit;
  right: 2rem !important;
  top: 2rem !important;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 100% !important;
}

/* General sidebar styles */
.bm-menu {
  background: var(--background);
  font-size: 1rem;
  font-weight: 700;
}

/* Wrapper for item list */
.bm-item-list {
  margin-top: 4rem;
  position: fixed;
  left: 1rem;
}

.bm-item {
  padding-left: 0;
}

/* Individual item */
.link {
  list-style: none outside none;
  color: var(--text-primary);
  margin-top: 4rem;
}

.logo-menu {
  position: fixed;
  top: 2rem;
}

.loading {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

#Menu {
  top: 0;
}
