@use 'styles/_colors.scss';
@use 'styles/position.scss';

$padding-mobile: 16px;
$padding-desktop: 32px;

.wrapper {
  position: relative;
  overflow-x: hidden;
  background: var(--background);
}

.circle {
  position: absolute;

  width: 100%;
  height: 250px;

  z-index: -1;

  background-color: colors.$primary;
}

.header {
  display: flex;

  height: 32px;

  align-items: center;
  justify-content: space-between;

  padding: 0 $padding-mobile;
  padding-top: 2.5rem;
  padding-bottom: 62px;

  background-color: colors.$primary;

  .navMobile {
    display: block;
  }
  .navDesktop {
    display: none;
  }

  .accessButton {
    position: absolute;
    text-transform: uppercase;
    font-size: 14px;
    transform: translateX(-4.75rem) translateY(0.15rem);
  }

  @media (min-width: 1200px) {
    height: 64px;

    padding: 0 $padding-desktop;
    padding-top: 2.5rem;
    padding-bottom: 148px;

    .navMobile {
      display: none;
    }

    .navDesktop {
      display: flex;
      justify-content: space-around;
      min-height: 64px;
      margin-top: 2.75rem;
      & > div > a {
        margin-right: 72px;
      }
      & > div > a:last-child {
        margin-right: 0;
      }
    }
  }
}

@media (min-width: 1200px) {
  .navDesktop {
    margin-top: 3rem;
  }
}

.main {
  &>section {
    padding: 0 32px;

    @media (min-width: 1200px) {
      padding: 0 72px;
    }
  }
}

.menuItem {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
}

.menuItemFix {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  margin-right: 72px;
  margin-top: 4px;
}

@mixin MenuItemFixed {
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: none;
  display: none;
  position: absolute;
  background-color: var(--background);
  border-radius: 8px;
  min-width: 100px;
  min-height: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.menuItemFixContent {
  @include MenuItemFixed;
}

.menuItemFixContent li {
  color: var(--primary);
  padding: 1.5rem 0.5rem 0.1rem 0.5rem;
  &:last-child {
  padding-bottom: 1.4rem;
  }
}

.menuItemFixContent li:hover > div > span {
  border-bottom: var(--primary) 2px solid;
  padding-right: 5rem;
}

.menuItemFixContent li:hover > a > span {
  border-bottom: var(--primary) 2px solid;
}

.menuItemFixContent li span {
  color: var(--text-primary);
  padding-right: 1rem;
  margin-left: 0;
}

.menuItemFixContent li > div:first-child {
  color: var(--text-primary);
  padding-right: 1rem;
}
 .menuItemFix:hover .menuItemFixContent {
  display: block;
}

.subMenuItemFixContent {
  @include MenuItemFixed;
  margin-left: 13.5rem;
  margin-top: -2.5rem;
  min-width: 350px;
  list-style: none;
}

.subMenuItemFixContent a  {
  color: var(--text-primary);
  padding: 1.5rem 0 0.5rem 1rem;
  display: block;
}

.subMenuItemFixContent a:last-child  {
  padding-bottom: 1.5rem;
}

.subMenuItemFixContent a:hover {
  background-color: var(--background-button);
}

.menuItemFixContent > li:first-child:hover .subMenuItemFixContent  {
  display: block;
}

.linkLogo {
    float: left;
    margin-top: -0.1rem;
    margin-right: 0.5rem;
}

.sideMenu {
  font-size: 0.875rem;
  list-style: disc;
  margin-left: 2rem;
  padding: 0.5rem;
  color: var(--primary);
}

.sideMenu .sideMenuText {
  color: var(--text-primary);
}

.sideMenu .sideMenuText:hover {
  border-bottom: var(--primary) 2px solid;
}

.firstSection {
  background-color: colors.$primary;
  margin-bottom: 32px;

  .blankSpace {
    width: 100%;
    height: 64px;

    @media (min-width: 1200px) {
      height: 120px;
    }
  }

  @media (min-width: 1200px) {
    margin-bottom: 40px;
  }

  .titleWrapper {
    margin-bottom: 25px;
    h1 {
      display: inline;
    }
  }
  p {
    margin-bottom: 32px;
  }
}

.secondSection {
  margin-bottom: 32px;

  h1 {
    margin-bottom: 16px;
    max-width: 270px;
    @media (min-width: 500px) {
      max-width: none;
    }
  }
  p {
    margin-bottom: 32px;
    max-width: 1010px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 64px;
  }
}

.thirdSection {
  margin-bottom: 32px;
  display: grid;
  justify-content: space-around;
  grid-gap: 2rem;

  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 32px;
    grid-gap: 4rem;
  }

  article {
    min-width: 320px;
  }
}

.fourthSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;

  @media (min-width: 1200px) {
    margin-bottom: 40px;
  }

  p,
  h1 {
    text-align: center;
    margin-bottom: 17px;
  }
}

@mixin button {
  @include position.center;
  
  padding: 16px 20px;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 2px;
  color: white;
  width: 100%;
  border-radius: 4px;

  &.primary { background: var(--background-button2) }

  &.contrast { background: colors.$black; }

  @media (max-width: 400px) {
    letter-spacing: 1px;
  }
}

.buttonLink {
  @include position.center;
  @include button();

  @media (min-width: 600px) {
    max-width: 353px;

    &.maxWidth {
      max-width: 353px;
    }
  }
}

.buttonLinkEnd {
  @include position.center;
  @include button();

  @media (min-width: 600px) {
    max-width: 492px;

    &.maxWidth {
      max-width: 492px;
    }
  }

  &:hover {
    background: var(--background-button2-hover)
  }
}

.footer {
  background: colors.$footer-background;
  color: colors.$secondary;

  padding: 56px 32px 27px 32px;

  .copyRight {
    padding: 24px 0;
  }

  .bottom {
    .socialMedia {
      & > a {
        margin-right: 12px;
      }
    }
  }

  @media (min-width: 1200px) {
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    padding: 48px 72px 34px 72px;
  }
}
